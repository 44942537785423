body {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slider-text-wrap {
    opacity: 1!important
}

.small-bubble-holder,.video-holder {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%
}

body {
    margin: 0;
    min-height: 100%;
    background-color: #fff
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
    display: block
}

a {
    background-color: transparent
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle
}

button,input,optgroup,select,textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,select {
    text-transform: none
}

input {
    line-height: normal
}

[class*=" w-icon-"],[class^=w-icon-] {
    font-family: webflow-icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

* {
    box-sizing: border-box
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898ec;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0
}

input.w-button {
    -webkit-appearance: button
}

.w-container:after,.w-embed:after,.w-radio:after,.w-row:after,.w-slider {
    clear: both
}

.w-form-done,.w-slider,.w-widget-map .gm-style-iw,.w-widget-twitter-count-shim,figcaption {
    text-align: center
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    margin-bottom: 10px
}

p {
    margin-top: 0
}

blockquote,figure {
    margin: 0 0 10px
}

button,html input[type=button],input[type=reset] {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

.w-form {
    margin: 0 0 15px
}

.w-input,.w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc
}

.w-slider {
    position: relative;
    height: 300px;
    background: #ddd;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent
}

.w-slider-arrow-left,.w-slider-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
    font-size: 40px;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-slider-arrow-left [class*=" w-icon-"],.w-slider-arrow-left [class^=w-icon-],.w-slider-arrow-right [class*=" w-icon-"],.w-slider-arrow-right [class^=w-icon-] {
    position: absolute
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto
}

.w-icon-slider-left,.w-icon-slider-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1em;
    height: 1em
}

h3,li,p {
    margin-bottom: 30px
}

.banner-wrap,.big-container,.w-nav-link {
    margin-right: auto;
    margin-left: auto
}

.big-container,.form,.slider-mask,.w-nav-overlay,.w-tab-content {
    overflow: hidden
}

.banner-blue,.big-container,.w-nav-button,.w-tab-content,.w-tab-link,.w-tab-menu,.w-tab-pane,.w-tabs {
    position: relative
}

.banner-blue-holder,.center,.footer-content,.form-heading,.grey-text,.h2-center,.heading-3.for-heading,.paragraph-small.footer-paragraph,.price-for-box,.small-bubble-holder,.social-link,.utility-page-content,.video-link,.white-paragraph,.white-text {
    text-align: center
}

body {
    font-family: Montserrat,sans-serif;
    color: #000;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500
}

h1,h2,h3,h4 {
    font-family: Josefin Sans,sans-serif;
    font-weight: 700;
    margin-top: 0
}

h3 {
    font-size: 54px;
    line-height: 66px;
    letter-spacing: -.2px
}

h4 {
    font-size: 22px;
    line-height: 32px
}

.paragraph-small,p {
    font-family: Montserrat,sans-serif
}

p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500
}

a {
    text-decoration: none
}

img {
    display: block
}


.paragraph-small {
    font-size: 14px;
    line-height: 26px;
    font-weight: 500
}

.cta,.underline-link {
    font-family: Josefin Sans,sans-serif;
    text-transform: uppercase
}

.paragraph-small.blue-paragraph {
    margin-bottom: 40px;
    color: #fff
}


.underline-link {
    border-bottom: 2px solid hsla(0,0%,100%,0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #fcbb5a;
    font-size: 11px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: none
}

.underline-link:hover {
    padding-bottom: 5px;
    border-bottom-color: #fcbb5a;
    letter-spacing: 1px
}

.underline-link.blue-link {
    display: inline;
    padding-bottom: 5px;
    border-bottom-color: #404cff;
    color: #404cff;
    font-size: 14px;
    line-height: 24px
}

.underline-link.blue-link:hover {
    display: inline;
    padding-bottom: 0;
    border-bottom-color: transparent
}

.banner,.banner-cta-holder {
    display: -ms-flexbox
}

.big-container {
    max-width: 1920px
}

.banner-wrap {
    max-width: 1563px;
    padding-top: 20px;
    padding-bottom: 0
}

.slider,.slider-holder {
    background-size: contain;
    background-repeat: no-repeat
}

.slider-content,.slider-image,.slider-mask,.sub-banner-content {
    margin-right: auto;
    margin-left: auto
}

.slider-section {
    padding-right: 21px;
    padding-left: 21px
}

.slider-content {
    max-width: 1200px;
    padding-top: 90px;
    padding-bottom: 20px
}

.slider-holder {
    display: flex;
    width: 70%;
    padding-bottom: 150px;
    justify-content: center;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5c9b6ca4bcc30e01c93aa14a_Shape%204%20copy.svg);
    background-position: 50% 100%
}

.icon,.icon-2,.slide-nav {
    display: none
}

.slider {
    width: 384px;
    min-height: 774px;
    background-color: transparent;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5ca205624ccfeb56f1c3b6f5_phone-case.png);
    background-position: 50% 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.slider-mask {
    width: 333px;
    height: auto;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px
}

.slider-image {
    margin-top: 20px
}

.slider-left-arrow,.slider-right-arrow {
    margin-top: 140%;
    background-position: 50% 50%;
    background-size: 55px;
    background-repeat: no-repeat
}

.slider-left-arrow {
    width: 55px;
    height: 54px;
    margin-left: -70px;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5c9b76cbbcc30e601a3ad78f_slider%20left%20btn%402x.png)
}

.slider-inner,.small-white-bubble {
    display: -ms-flexbox
}

.slider-right-arrow {
    width: 56px;
    height: 55px;
    margin-right: -70px;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5c9b76d5bcc30e6f3e3ad79f_slider%20right%20btn%402x.png)
}

.slider-inner {
    display: flex;
    align-items: flex-start
}

.slider-text-wrap {
    width: 46%;
    margin-top: 90px;
    margin-left: -10%
}

.border-box,.content-4,.grey-text.small-width,.price-content,.white-paragraph {
    margin-right: auto;
    margin-left: auto
}

.no-margin-bottom {
    margin-bottom: 0
}

.cta {
    display: block;
    width: 208px;
    height: 60px;
    padding: 0;
    flex: 0 0 auto;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    line-height: 64px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px
}

.footer-brand,.footer-brand.w--current {
    display: inline-block;
    margin-right: auto;
    margin-left: auto
}

.footer-section,.header {
    padding-right: 20px;
    padding-left: 20px
}

.form-heading,.form-input {
    font-family: Montserrat,sans-serif
}


.cta.cta-header {
    width: 166px;
    height: 48px;
    border: 2px solid #000;
    background-color: hsla(0,0%,100%,0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #000;
    font-size: 14px;
    line-height: 50px
}

.cta.cta-header:hover {
    background-color: #000;
    color: #fff
}

.cta.cta-header.cta-mobile {
    width: 100%;
    font-size: 23px
}

.cta.cta-form {
    width: 166px;
    height: 48px;
    background-color: #404cff;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    font-size: 14px;
    line-height: 51px
}

.cta.cta-form:hover {
    background-color: #2d38d2
}

.cta.cta-form.center {
    margin-right: auto;
    margin-left: auto
}

.footer-section {
    position: relative;
    z-index: 100;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5c9b77ffcccd3e2c91a9ed08_Shape%204%20footer.svg);
    background-position: 50% 0;
    background-size: 1919px;
    background-repeat: no-repeat
}

.form,.play-button {
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.footer-content {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 100px;
    padding-bottom: 100px
}

.footer-brand {
    margin-bottom: 60px;
    justify-content: center;
    align-items: center
}

.social-link,.social-wrap {
    display: -ms-flexbox;
    -webkit-box-pack: center
}

.social-link {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #fff;
    -webkit-transition: padding-bottom .3s ease;
    transition: padding-bottom .3s ease
}

.green-link,.yellow-link {
    -webkit-transition: all .3s ease;
    text-decoration: none
}

.social-link:hover {
    padding-bottom: 10px;
    line-height: 40px
}

.social-link.green {
    background-color: #76e0d5
}

.social-link.pink {
    background-color: #f856aa
}

.social-link.yellow {
    background-color: #fcba5b
}

.social-wrap {
    display: flex;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center
}

.footer-link-block {
    margin-right: 3%;
    margin-left: 3%
}

.footer-links-wrap {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    list-style-type: none
}

.yellow-link {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #fcba5b
}

.yellow-link:hover {
    color: rgba(252,186,91,.7)
}

.green-link {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #76e0d5
}

.green-link:hover {
    color: rgba(118,224,213,.7)
}

.nav-link,.text-video-span {
    color: #000
}

.banner-section {
    display: block
}

.header,.price-for-holder {
    display: -ms-flexbox
}

.price-for-holder {
    display: flex;
    margin-bottom: 130px;
    justify-content: space-around;
    align-items: stretch
}

.colors-holder,.header {
    -webkit-box-pack: justify
}

.price-for-box {
    width: 30%
}

.header {
    margin-bottom: 20px;
    justify-content: space-between
}

.header,.navbar {
    display: flex;
    align-items: center
}

.navbar {
    position: relative;
    z-index: 20;
    margin-bottom: 0;
    justify-content: flex-end
}

.hamburger-button,.video-paragraph {
    -webkit-box-orient: vertical
}

.nav-link {
    display: inline-block;
    border-bottom: 2px solid transparent;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-decoration: none
}

.nav-link:hover {
    padding-bottom: 5px;
    border-bottom-color: #000
}

.navlink {
    margin-left: 45px
}

.hamburger-button {
    display: none;
    width: 40px;
    height: 26px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center
}

.bottom-line,.middle-line,.top-line {
    width: 100%;
    height: 3px
}

.hamburger-button.close {
    position: fixed;
    left: auto;
    top: 20px;
    right: 20px;
    bottom: auto;
    z-index: 9002;
    display: flex
}

.top-line {
    background-color: #f11f73
}

.middle-line {
    background-color: #f98a23
}

.bottom-line {
    background-color: #55d2c3
}

.nav-menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9004;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #fff
}

.footer-links-holder,.icon-style-guide,.imput,.links-align,.lock,.not-found {
    margin-right: auto;
    margin-left: auto
}

.form-holder {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
    padding: 40px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.9)
}

.colors-holder,.form {
    display: -ms-flexbox
}

.form {
    display: flex;
    margin-bottom: 0;
    padding: 5% 17% 5% 8%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(https://uploads-ssl.webflow.com/5c93901939f21ebf812cec22/5ca08081b7e3ed2d721e7a3b_form-bg.png);
    background-size: contain;
    font-style: normal
}

.form-heading {
    margin-bottom: 15px;
    color: #404cff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500
}

.form-input,.imput {
    border-radius: 28px;
    line-height: 60px;
    font-weight: 400;
    text-indent: 40px
}

.form-input {
    width: 375px;
    height: 60px;
    margin-bottom: 20px;
    padding: 0;
    color: #000;
    font-size: 14px
}

.video-holder {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    background-color: rgba(0,0,0,.9);
    opacity: 0
}

._404-page-div,.footer-links-holder {
    display: -ms-flexbox
}

.footer-links-holder {
    display: flex;
    justify-content: center;
    align-items: center
}


@media (max-width: 991px) {
    .banner-blue,.blue-section {
        background-repeat:no-repeat
    }

    .bottom-line,.middle-line,.top-line {
        background-color: #000
    }

    h1 {
        font-size: 70px;
        line-height: 80px
    }

    h2 {
        font-size: 60px;
        line-height: 70px
    }

    h3 {
        font-size: 45px;
        line-height: 55px
    }

    .banner-blue,.slider-holder {
        background-position: 50% 50%
    }

    .slider-content {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .slider-holder {
        width: 100%;
        padding-bottom: 90px
    }

    .content-4,.footer-content,.price-content {
        padding-bottom: 60px
    }

    .slider-left-arrow,.slider-right-arrow {
        margin-top: auto;
        margin-bottom: auto
    }

    .slider-inner {
        flex-direction: column-reverse
    }

    .slider-text-wrap {
        width: 100%;
        margin-top: 0;
        margin-bottom: 40px;
        margin-left: 0;
        text-align: center;

        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 40px;
    
    }

    .footer-content {
        padding-top: 80px
    }


    .navbar {
        display: none
    }

    .hamburger-button {
        position: relative;
        z-index: 100;
        display: flex;
        width: 30px;
        height: 23px
    }

    .bubbles-colors,.green-bottom-left-bubble,.green-top-left-bubble,.middle-line.mobile,.nav-menu,.red-bubble,.small-blue-bubble,.small-green-bubble,.small-red-bubble,.video-arrow-right,.yellow-bottom-right-bubble,.yellow-top-right-bubble {
        display: none
    }

    .form,.form-input {
        width: 100%
    }

    .nav-menu {
        z-index: 9004
    }

    .form {
        padding-top: 20%;
        padding-bottom: 20%;
        background-size: 100%
    }
}

@media (max-width: 767px) {
    .banner-blue,.banner-paragraph,.slider-holder {
        margin-right: auto;
        margin-left: auto
    }

    .banner-yellow,.green-top-bubble,.nav-menu {
        display: none
    }

    h3 {
        font-size: 35px;
        line-height: 45px
    }

    .paragraph-small.footer-paragraph {
        margin-bottom: 15px
    }

    .underline-link {
        font-size: 14px;
        line-height: 24px
    }

    .slider-content {
        padding-top: 40px;
        padding-bottom: 60px
    }

    .slider-holder {
        padding-bottom: 10%;
        background-position: 50% 100%;
        background-size: contain
    }

    .slider {
        width: 300px;
        min-height: 602px
    }

    .slider-right-arrow {
        margin-bottom: auto
    }

    .footer-brand,.price-for-holder {
        margin-bottom: 40px
    }

    .footer-section {
        background-position: 50% 0
    }

    .footer-content {
        padding-bottom: 40px
    }

    .form-holder {
        padding: 20px
    }

    .form {
        padding-top: 8%;
        padding-right: 8%;
        padding-bottom: 8%;
        border-radius: 30px;
        background-image: -webkit-linear-gradient(335deg,#fdd996 20%,#f98820 90%);
        background-image: linear-gradient(115deg,#fdd996 20%,#f98820 90%);
        background-position: 0 0
    }
}

@media (max-width: 479px) {
    .border-box,.slider-holder {
        background-repeat:repeat;
        background-image: none
    }

    .slider-holder {
        background-position: 0 0;
        background-size: auto
    }

    .slider {
        width: 220px;
        min-height: 441px;
        background-size: contain;
        -webkit-transform: scaleX(1none);
        transform: scaleX(1none)
    }

    .slider-left-arrow,.slider-right-arrow {
        width: 34px;
        height: 34px;
        background-size: cover
    }

    .slider-left-arrow {
        margin-left: -45px
    }

    .slider-right-arrow {
        margin-right: -45px
    }

    .footer-section {
        background-position: 50% 100%
    }

    .footer-content {
        padding-top: 100px;
        padding-bottom: 20px
    }

    .footer-link-block {
        margin-right: 10%;
        margin-left: 10%
    }

    .footer-links-wrap {
        flex-direction: column;
        align-items: center
    }

    .green-link,.yellow-link {
        display: block
    }

    .footer-links-holder {
        margin-bottom: 15px;
        flex-direction: column
    }
}
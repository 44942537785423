h4 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}

.slick-slider button {
    display: none !important;
}

.slick-track {
    display: flex;
}

#innerPhoneContainer {
    margin-top: 23px;
    margin-left: 24px;
    margin-right: 25px;
    height: 94%;
    background: white;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

div#innerPhoneContainer * {
    height: 100% !important;
}

.slider {
    background-image: url('./phone-case.png') !important;
}



@media (max-width:991px) { 
    #innerPhoneContainer {
        margin-top: 23px;
        margin-left: 24px;
        margin-right: 25px;
        height: 94%;
        background: white;
        overflow: hidden;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    
}

@media (max-width:767px) { 
    #innerPhoneContainer {
        margin-top: 16px;
        margin-left: 18px;
        margin-right: 18px;
        height: 95%;
        background: white;
        overflow: hidden;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        border-bottom-left-radius: 28px;
        border-bottom-right-radius: 28px;
    }
}

@media screen and (max-width:479px) {
    #innerPhoneContainer {
        margin-top: 10px;
        margin-left: 14px;
        margin-right: 14px;
        height: 95%;
        background: white;
        overflow: hidden;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        border-bottom-left-radius: 23px;
        border-bottom-right-radius: 23px;
    }
}
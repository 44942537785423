.slider-text-wrap {
  opacity: 1 !important;
  padding: 10px 30px;
  color: white;
  border-radius: 5px;
}

.no-margin-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  box-shadow: 9.2px 37.5px 125px -25px rgba(50,50,93,.5) !important;
  padding: 16px;
  border-radius: 70px;
  cursor: pointer;
}

.store-link {
  max-width: 200px;
  object-fit: contain;
  max-height: 60px;
}

.slider-holder {
  background-image: url('./yellow-blob.svg') !important;
  background-repeat: no-repeat;
}

.slider-button {
  width: 56px;
  height: 56px;
  background-color: #266ef1;
  background-image: linear-gradient(#266ef1, #266ef1, 90deg);
  border-radius: 56px;

  margin: auto;
  margin-top: 140%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button img {
  width: 50%;
  object-fit: contain;
}

.slider-button-right {
  margin-right: -70px;
}

.slider-button-left {
  margin-left: -70px;
}


@media (max-width:991px) { 

  .slider-button {
    margin-top: auto;
  }
  /* .slider-section {
    margin-top: 70px;
  } */
  
}

@media screen and (max-width:479px) {

  .slider-holder {
    background-position: 50% 100% !important;
  }

  .slider-button {
    width: 34px;
    height: 34px;
    border-radius: 34px;
  }

  .slider-button-left {
    margin-left: -45px;
  }

  .slider-button-right {
    margin-right: -45px;
  }
}


.header-icon {
    height: 100%;
    border-radius: 15%;
    box-shadow: 0px 0px 16px #0000001f;
}

.header-text {
  margin: 0px;
  color: #266ef1;
  font-size: 35px;
  margin-left: 20px;
}

.banner-section {
  /* background-color: #266ef1; */
    /* position: fixed; */
    width: 100%;
    z-index: 1234132;
}





/* CUSTOM */

/* .slider-section {
  margin-top: 50px;
} */


.banner-wrap {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 100px;
}

@media (max-width:991px) { 
  /* .slider-section {
    margin-top: 70px;
  } */
  
}

@media (max-width:767px) { 
  /* .slider-section {
      margin-top: 85px;
  } */
}

@media screen and (max-width:479px) {
  /* .slider-section {
      margin-top: 48px;
  } */

  .banner-wrap {
    display: flex;
    align-items: center;
    padding: 11px 16px;
    height: 66px;
  }
}